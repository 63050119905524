<template>
  <div>
    <div v-if="!showEmailSignup">
      <h3 class="text-4xl font-bold mb-6">
        After your dream job?
      </h3>
      <p class="font-light mb-6">
        We help graduate engineers find their first jobs. Signup and create your
        free profile to get started.
      </p>

      <div class="mb-6">
        <button
          class="flex w-full text-white border-2 border-gray-400 hover:border-gray-600 text-gray-700 focus:shadow-outline pl-2 py-2 h-12 rounded block"
          @click="federatedSignIn('Google')"
        >
          <span class="flex flex-shrink-0">
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M20.64 12.2c0-.63-.06-1.25-.16-1.84H12v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.62z"
                  fill="#4285F4"
                />
                <path
                  d="M12 21a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.4 5.4 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 12 21z"
                  fill="#34A853"
                />
                <path 
                  d="M6.96 13.71a5.41 5.41 0 0 1 0-3.42V7.96h-3a9 9 0 0 0 0 8.08l3-2.33z"
                  fill="#FBBC05"
                />
                <path
                  d="M12 6.58c1.32 0 2.5.45 3.44 1.35l2.58-2.59A9 9 0 0 0 3.96 7.95l3 2.34A5.36 5.36 0 0 1 12 6.58z"
                  fill="#EA4335"
                />
              </g></svg></span><span
            class="flex-1 text-center block justify-center"
          >Sign up with Google</span>
        </button>
      </div>

      <div class="w-full mb-6">
        <button
          class="flex w-full text-white border-2 border-gray-400 hover:border-gray-600 active:bg-blue-700 text-gray-700 pl-2 py-2 h-12 rounded"
          @click="federatedSignIn('Facebook')"
        >
          <span class="flex flex-shrink-0 facebook">
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h8.61v-6.97h-2.34V11.3h2.34v-2c0-2.33 1.42-3.6 3.5-3.6 1 0 1.84.08 2.1.12v2.43h-1.44c-1.13 0-1.35.53-1.35 1.32v1.73h2.69l-.35 2.72h-2.34V21h4.59a1 1 0 0 0 .99-1V4a1 1 0 0 0-1-1z"
                fill="currentColor"
              />
              <path
                d="M12.61 14.03V21h2.81v-6.98h2.34l.35-2.72h-2.69V9.57c0-.79.22-1.32 1.35-1.32h1.44V5.82c-.26-.04-1.1-.12-2.1-.12-2.08 0-3.5 1.27-3.5 3.6v2h-2.34v2.73h2.34z"
                fill="#fff"
              /></svg></span><span
            class="flex-1 text-center block justify-center"
          >Sign up with Facebook</span>
        </button>
      </div>

      <div class="w-full mb-6">
        <button
          class="w-full shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded"
          type="submit"
          @click="toggleShowEmailSignup"
        >
          Sign up with Email
        </button>
      </div>

      <div>
        <p class="font-light mb-6 text-sm">
          Already signed up?
          <router-link
            to="/login"
            class="font-light mb-6 text-sm text-orange-500 underline"
          >
            Login
          </router-link>
        </p>
      </div>
    </div>

    <SignupForm v-if="showEmailSignup" />
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
import SignupForm from "./SignupForm";

export default {
  name: "Signup",
  components: {
    SignupForm,
  },
  data() {
    return {
      showEmailSignup: false,
    };
  },
  methods: {
    async federatedSignIn(provider) {
      await Auth.federatedSignIn({ provider });
    },
    toggleShowEmailSignup() {
      this.showEmailSignup = true;
    },
  },
};
</script>

<style scoped>
.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.facebook {
  color: #3b5998;
}
.fa-lg {
  vertical-align: middle;
}
</style>
