<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <div class="flex h-screen pb-16">
      <loading
        :active.sync="isSubmitted"
        color="#ff9300"
      />

      <form
        class="w-full bg-white  m-auto"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <div class="pt-6 pb-6">
          <h3 class="text-4xl font-bold mb-6">
            After your dream job?
          </h3>
          <p class="font-light mb-6">
            We help graduate engineers find their first jobs. Signup and create
            your free profile to get started.
          </p>
          <div class="w-full mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >Firstname</label>

            <ValidationProvider
              v-slot="{ errors }"
              name="firstname"
              rules="required"
              mode="eager"
            >
              <input
                v-model="register.firstname"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="text"
                placeholder="Jane"
              >
              <span class="text-red-500 text-xs inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="w-full mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="lastname"
            >Lastname</label>

            <ValidationProvider
              v-slot="{ errors }"
              name="lastname"
              rules="required"
              mode="eager"
            >
              <input
                v-model="register.lastname"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="text"
                placeholder="Smith"
              >
              <span class="text-red-500 text-xs inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="w-full mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="email"
            >Email</label>

            <ValidationProvider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
              mode="eager"
            >
              <input
                v-model="register.email"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="email"
                placeholder="jane@example.com"
              >
              <span class="text-red-500 text-xs inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>

          <div class="w-full mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="password"
            >Password</label>
            <ValidationProvider
              v-slot="{ errors }"
              name="password"
              rules="required|min:8|verify_password"
              mode="eager"
            >
              <input
                v-model="register.password"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="password"
                :placeholder="passwordPlaceholder"
              >
              <p class="text-red-500 text-xs inline-block">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>

          <div class="mb-4">
            <button
              class="w-full shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded"
              type="submit"
            >
              Sign up with Email
            </button>
          </div>
          <div>
            <p class="font-light mb-6 text-sm">
              Already signed up?
              <router-link
                to="/login"
                class="font-light mb-6 text-sm text-orange-500 underline"
              >
                Login
              </router-link>
            </p>
          </div>
        </div>
      </form>
    </div>
  </ValidationObserver>
</template>

<script>
import Loading from "vue-loading-overlay";
import { REGISTER_STUDENT } from "../../store/actions.type";
import { mapGetters } from "vuex";

export default {
  name: "RegisterStudent",
  components: {
    Loading,
  },
  data() {
    return {
      register: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
      },
      passwordPlaceholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022",
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters(["isUserConfirmed"]),
  },
  methods: {
    async onSubmit() {
      this.isSubmitted = true;

      await this.$store.dispatch(REGISTER_STUDENT, {
        username: this.register.email,
        password: this.register.password,
        attributes: {
          email: this.register.email,
          given_name: this.register.firstname,
          family_name: this.register.lastname,
          "custom:user_type": "student", // custom attribute
        },
      });

      this.isSubmitted = false;

      console.log(this.isUserConfirmed);
      if (this.isUserConfirmed === false) {
        this.$router.push({ name: "confirmRegistration" });
      }
    },
  },
};
</script>
