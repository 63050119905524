var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "div",
              { staticClass: "flex h-screen pb-16" },
              [
                _c("loading", {
                  attrs: { active: _vm.isSubmitted, color: "#ff9300" },
                  on: {
                    "update:active": function($event) {
                      _vm.isSubmitted = $event
                    }
                  }
                }),
                _c(
                  "form",
                  {
                    staticClass: "w-full bg-white  m-auto",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "pt-6 pb-6" }, [
                      _c("h3", { staticClass: "text-4xl font-bold mb-6" }, [
                        _vm._v(" After your dream job? ")
                      ]),
                      _c("p", { staticClass: "font-light mb-6" }, [
                        _vm._v(
                          " We help graduate engineers find their first jobs. Signup and create your free profile to get started. "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "w-full mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                              attrs: { for: "firstname" }
                            },
                            [_vm._v("Firstname")]
                          ),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "firstname",
                              rules: "required",
                              mode: "eager"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.register.firstname,
                                            expression: "register.firstname"
                                          }
                                        ],
                                        staticClass:
                                          "appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Jane"
                                        },
                                        domProps: {
                                          value: _vm.register.firstname
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.register,
                                              "firstname",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-red-500 text-xs inline-block"
                                        },
                                        [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                              attrs: { for: "lastname" }
                            },
                            [_vm._v("Lastname")]
                          ),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "lastname",
                              rules: "required",
                              mode: "eager"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.register.lastname,
                                            expression: "register.lastname"
                                          }
                                        ],
                                        staticClass:
                                          "appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Smith"
                                        },
                                        domProps: {
                                          value: _vm.register.lastname
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.register,
                                              "lastname",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-red-500 text-xs inline-block"
                                        },
                                        [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                              attrs: { for: "email" }
                            },
                            [_vm._v("Email")]
                          ),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "email",
                              rules: "required|email",
                              mode: "eager"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.register.email,
                                            expression: "register.email"
                                          }
                                        ],
                                        staticClass:
                                          "appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none",
                                        attrs: {
                                          type: "email",
                                          placeholder: "jane@example.com"
                                        },
                                        domProps: { value: _vm.register.email },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.register,
                                              "email",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-red-500 text-xs inline-block"
                                        },
                                        [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                              attrs: { for: "password" }
                            },
                            [_vm._v("Password")]
                          ),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "password",
                              rules: "required|min:8|verify_password",
                              mode: "eager"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.register.password,
                                            expression: "register.password"
                                          }
                                        ],
                                        staticClass:
                                          "appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none",
                                        attrs: {
                                          type: "password",
                                          placeholder: _vm.passwordPlaceholder
                                        },
                                        domProps: {
                                          value: _vm.register.password
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.register,
                                              "password",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-red-500 text-xs inline-block"
                                        },
                                        [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mb-4" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-full shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(" Sign up with Email ")]
                        )
                      ]),
                      _c("div", [
                        _c(
                          "p",
                          { staticClass: "font-light mb-6 text-sm" },
                          [
                            _vm._v(" Already signed up? "),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "font-light mb-6 text-sm text-orange-500 underline",
                                attrs: { to: "/login" }
                              },
                              [_vm._v(" Login ")]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }